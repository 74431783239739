@use 'src/styles/colors' as c;

.Terminal {
  font-family: Inconsolata, monospace;
  color: c.$cmd-line-grey;

  pre {
    color: c.$cmd-line-grey;
  }
}
