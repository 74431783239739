@use 'src/styles/colors' as c;

nav.navbar {
  border-bottom: solid 2px c.$cmd-line-grey;
  margin-bottom: 15px;

  span.blend {
    color: c.$cmd-line-grey;
  }
}
